import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import moment from 'moment';
import PropTypes from "prop-types";

const ProductivityPastDays = (props) => {
  const [chart, setChart] = React.useState(null);
  const [sComplete, setData] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const chartRef = React.useRef(null);
  
  const chartCallback = c => setChart(c);
  
  React.useEffect(() => {
    if(chartRef.current){
      chartRef.current.chart.reflow();
    }

  }, [props.refreshKey])
  React.useEffect(() => {
   
    console.time('ProductivityPastDays');
    let sComplete = [];
    
    if (props.data){
      let day = subtractDayWithWeekends(props.startDate);
      let title1 = day.format('MM-DD-YYYY');
      day = subtractDayWithWeekends(day);
      let title2 = day.format('MM-DD-YYYY');
      day = subtractDayWithWeekends(day);
      let title3 = day.format('MM-DD-YYYY');
      day = subtractDayWithWeekends(day);
      let title4 = day.format('MM-DD-YYYY');
      day = subtractDayWithWeekends(day);
      let title5 = day.format('MM-DD-YYYY');
      setCategories([title1,title2,title3,title4,title5]);
      sComplete =  [{name: "Closed Tickets",data : props.data, color : "#7cb5ec"}];
      setData(sComplete);
    }

    console.timeEnd('ProductivityPastDays');

  }, [props.data])

  const subtractDayWithWeekends = (startDate) => {
    let day = moment(startDate).subtract(1, 'days');
    while(day.isoWeekday() > 5){
      day = moment(day).subtract(1, 'days');
    }
    return day;
  }


  return(
    <HighchartsReact
      containerProps={{ className: " border border-dark", style : {marginTop : 5, marginBottom : 5}}}
      highcharts={Highcharts}
      allowChartUpdate={true}
      callback={chartCallback}
      constructorType="chart"
      ref={chartRef}
      options={{
        chart : { type: "column", backgroundColor : "#E1E9EF"},
        title: { text: ""},
        plotOptions:{
          column:{
            stacking : 'normal',
            dataLabels: {
              enabled: true,
            },
          }
    },
        xAxis: { categories: categories || []},
        yAxis: { title: { text : "Tickets by Date"}},
        tooltip: { shared: true },
        series: sComplete,
        credits: {enabled: false},
        legend: {enabled: false}
      }}
    />
  )
}

ProductivityPastDays.propTypes = {
  data : PropTypes.array,
  refreshKey : PropTypes.number,
  filterCallback : PropTypes.func,
  useModified: PropTypes.bool
}

export default ProductivityPastDays;