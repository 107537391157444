import React from 'react';
import PropTypes from 'prop-types';
import { MDBDataTable } from 'mdbreact';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Button from 'react-bootstrap/Button';
import DateRange from '@material-ui/icons/DateRange';
import { BarLoader } from 'react-spinners';
import auth from '../../common/Auth';
import ProductivityPastDays from './ProductivityPastDays';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
const GridLayout = WidthProvider(ReactGridLayout);

const defaultLayout = [
  {i: "dataTable", x:0, y:0, w:12, h:150, static: false, minH:0, maxH:150}
]

class TicketProductivityTab extends React.Component{
  constructor(props){
    super(props);
    let today = new Date();  
    today.setDate(today.getDate());
    
    this.state={
      layout: defaultLayout,
      startPickerOpen : false,
      syncText: "- no sync -",
      startDate: today.toLocaleDateString(),
      refreshKeys:[],
      loading : false,
      ticketAssignmentAreas : [],
      CompanyArcGisLayerFeatures : [],
      filterTicketFeatures : [],
      selectedFeatures : [],
      selectedAreas : [],
      ticketGroups : [],
      ticketAssignmentAreaGroups : [],
      userAssignmentAreas: [{}],
      searchData : [],
      searchFilter : "",
      currentSort : "",
    };
  }

  componentDidMount(){
    this.searchData();
    this.getCompanyLayerFeatures();
    this.getTicketGroups();
    this.getUserAssignmentArea();
  }

  getUserAssignmentArea(){
      let body ={
        UserId : auth.employeeId(),
        CompanyId : auth.companyId()
      }
  
      fetch(process.env.API_URL + "/api/account/getUserAssignmentAreas",{
        method: 'POST',
        headers:{
          "Content-Type" : "application/json; charset=utf-8",
          Accept : "application/json",
          Authorization : "Bearer " + auth.token()
        },
        body : JSON.stringify(body)
      })
      .then(r =>{
        if(r.status == 200){
          r.json().then(responseJson =>{
            let json = JSON.parse(responseJson);
            if(json.ResponseType == 0){
              this.setState({userAssignmentAreas: json.UserAssignmentAreas});
              console.log('userAssignmentAreas', json.UserAssignmentAreas);
            }
          });
        }
      })
      .catch(error =>{
        console.log(error.message);
      });
  
    }

  getTicketGroups() {
    let body = {
      UserId: auth.employeeId(),
      CompanyId: auth.companyId(),
      Web: true,
    };
    fetch(process.env.API_URL + "/api/account/getTicketGroups", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: "Bearer " + auth.token(),
      },
      body: JSON.stringify(body),
    })
      .then((r) => {
        if (r.status == 200) {
          r.json().then((responseJson) => {
            let json = JSON.parse(responseJson);
            if (json.ResponseType == 0) {
              if(json.Groups){
                let ticketGroups = [];
                ticketGroups = ticketGroups.concat(json.Groups);
                let idMapping = ticketGroups.reduce((acc, el, i) =>{
                  acc[el.TicketGroupId] = i;
                  return acc;
                }, {});
                ticketGroups.forEach(group =>{
                  group.expanded = true;
                  if(!group.ParentId){
                    group.isRoot = true;
                    return;
                  }
                  let parent = ticketGroups[idMapping[group.ParentId]];
                  if(parent)parent.children = [...(parent.children || []), group];
                });
                console.log(ticketGroups);
                console.log(json.TicketAssignmentAreaGroups);
                this.setState({ticketGroups,ticketAssignmentAreaGroups : (json.TicketAssignmentAreaGroups || [])});
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCompanyLayerFeatures(){
    let body ={
      UserId : auth.employeeId(),
      CompanyId : auth.companyId(),
      Web : true,
      TicketArea : true
    }
    fetch(process.env.API_URL + "/api/account/getCompanyArcGisLayers",{
      method : "POST",
      headers:{
        "Content-Type" : "application/json; charset=utf-8",
        Accept : "application/json",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(r =>{
      if(r.status == 200){
        r.json().then(responseJson =>{
          let json = JSON.parse(responseJson);
          if(json.ResponseType == 0){
            if(json.FeatureCollection){
              this.setState({CompanyArcGisLayerFeatures : json.FeatureCollection, filterTicketFeatures : JSON.parse(JSON.stringify(json.FeatureCollection)), selectedFeatures : JSON.parse(JSON.stringify(json.FeatureCollection))});
            }
          }
        })
      }
    })
    .catch(error =>{
      console.log(error);
    })
  }

  searchData = () => {
    console.time('search');
    this.setState({loading : true});
    let body ={
      Date : new Date(new Date(this.state.startDate).setHours(0,0,0,0)),
      CompanyId : auth.companyId(),
      UserId : auth.employeeId(),
      UTCOffset : - (new Date().getTimezoneOffset() /60)
    }
    fetch(process.env.API_URL + "/api/TicketProductivity",{
      method : "POST",
      headers : {
        Accept : "application/json",
        "Content-Type" : "application/json; charset=utf-8",
        Authorization : "Bearer " + auth.token()
      },
      body : JSON.stringify(body)
    })
    .then(res => res.json())
    .then(r =>{
      if(r.length > 0){
        let response = JSON.parse(r);
        console.timeEnd('search');
        console.log('searchData()::', response);
        let refreshKeys = [];
        response.Results.forEach(() => {refreshKeys.push(0)});
        this.setState({refreshKeys});
        var searchData = [];
        let offset = - (new Date().getTimezoneOffset() /60);
        response.Results.forEach((element,index ) =>{
          if(!element.TicketsPerHour) element.TicketsPerHour = 0;
          else element.TicketsPerHour = element.TicketsPerHour.toFixed(2);
          if(!element.FirstSync && element.LastSync) element.FirstSync = element.LastSync;
          if(!element.FirstSync) element.FirstSync = this.state.syncText;
          else element.FirstSync = moment(element.FirstSync).add(offset,"hours").format('llll');
          if(!element.LastSync){
            if(!element.FirstSync) element.LastSync = this.state.syncText;
             else element.LastSync = element.FirstSync;
          }
          else element.LastSync = moment(element.LastSync).add(offset,"hours").format('llll');
          if(!element.TimeElapsed) element.TimeElapsed = "-";
          else element.TimeElapsed = element.TimeElapsed.toFixed(2);
          if(!element.TicketsOpen) element.TicketsOpen = 0;
          if(!element.TicketsClosed) element.TicketsClosed = 0;
          if(!element.TicketsProjected) element.TicketsProjected = 0;
          if(!element.DayCount1) element.DayCount1 = 0; 
          if(!element.DayCount2) element.DayCount2 = 0;
          if(!element.DayCount3) element.DayCount3 = 0;
          if(!element.DayCount4) element.DayCount4 = 0;
          if(!element.DayCount5) element.DayCount5 = 0;
          element.Chart = 
          <ProductivityPastDays filterCallback={this.filterTable} 
          refreshKey={this.state.refreshKeys[index]} 
          startDate={this.state.startDate} 
          data={[element.DayCount1,element.DayCount2,element.DayCount3,element.DayCount4,element.DayCount5]} 
          />
          
          searchData.push(element);
        });
        this.setState({currentSort : null,searchData, filteredSearchData : searchData, loading : false,displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
      }
      else this.setState({loading : false});
    })
    .catch(e => {this.setState({loading : false});})
  }

  setRefreshKeys(keys,value){
    for(let i = 0; i< keys.length; i++){
      keys[i] = value;
    }
  }

  handleSort = (columnToSort) => {
    if(columnToSort == "DisplayName"){
      if(this.state.currentSort == "DisplayName"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.DisplayName.localeCompare(b.DisplayName)),currentSort : "DisplayName"});
      }
    }
    else if(columnToSort == "TicketsPerHour"){
      if(this.state.currentSort == "TicketsPerHour"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.TicketsPerHour - b.TicketsPerHour),currentSort : "TicketsPerHour"});
      }
    }
    else if(columnToSort == "FirstSync"){
      if(this.state.currentSort == "FirstSync"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => {
          if(a.FirstSync == this.state.syncText && b.FirstSync == this.state.syncText) return 0;
          if(a.FirstSync == this.state.syncText) return -1;
          if(b.FirstSync == this.state.syncText) return 1;
          return new Date(b.FirstSync) - new Date(a.FirstSync);
        }),currentSort : "FirstSync"});
      }
    }
    else if(columnToSort == "LastSync"){
      if(this.state.currentSort == "LastSync"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => {
          if(a.LastSync == this.state.syncText && b.LastSync == this.state.syncText) return 0;
          if(a.LastSync == this.state.syncText) return -1;
          if(b.LastSync == this.state.syncText) return 1;
          return new Date(b.LastSync) - new Date(a.LastSync);
        }),currentSort : "LastSync"});
      }
    }
    else if(columnToSort == "TimeElapsed"){
      if(this.state.currentSort == "TimeElapsed"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.TimeElapsed - b.TimeElapsed),currentSort : "TimeElapsed"});
      }
    }
    else if(columnToSort == "TicketsOpen"){
      if(this.state.currentSort == "TicketsOpen"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.TicketsOpen - b.TicketsOpen),currentSort : "TicketsOpen"});
      }
    }
    else if(columnToSort == "TicketsClosed"){
      if(this.state.currentSort == "TicketsClosed"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.TicketsClosed - b.TicketsClosed),currentSort : "TicketsClosed"});
      }
    }
    else if(columnToSort == "TicketsProjected"){
      if(this.state.currentSort == "TicketsProjected"){
        this.setState({filteredSearchData : this.state.filteredSearchData.reverse()});
      }
      else{
        this.setState({filteredSearchData : this.state.filteredSearchData.sort((a,b) => a.TicketsProjected - b.TicketsProjected),currentSort : "TicketsProjected"});
      }
    }
    
  }

  startHandleChange = selectedDate => {
    selectedDate.setHours(new Date().getHours())
    selectedDate.setTime(selectedDate.getTime());
    this.setState({ startDate: selectedDate.toLocaleDateString()},() =>{this.searchData();});
  };

  toggleStartDatePicker = () => {
    this.setState({ startPickerOpen: !this.state.startPickerOpen });
  };

  isTicketGroupChecked(group){
    var isChecked = false;
    var ticketAssignmentAreaGroups = this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId);
    let foundAreas = [];
    let foundFeatures = [];
    var selectedGroupAssignments = [];
    var selectedGroupFeatures = [];
    ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
      if(assignmentAreaGroup.AssignmentAreaId){
        if(this.state.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId) != -1){
          foundAreas.push(assignmentAreaGroup);
        }
        var selectedAssignment = this.state.selectedAreas.find(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
        if(selectedAssignment) selectedGroupAssignments.push(selectedAssignment);
      }
      else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
        if(this.state.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId) != -1){
          foundFeatures.push(assignmentAreaGroup);
        }
        var selectedFeature = this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
        if(selectedFeature) selectedGroupFeatures.push(selectedFeature);
      }
    });
    isChecked = (foundAreas.length + foundFeatures.length) == (selectedGroupAssignments.length + selectedGroupFeatures.length);
    return isChecked;
  }

  getAllOffspring(ticketGroup){
    let idList = [];
    idList.push(ticketGroup.TicketGroupId);
    if(ticketGroup.children){
      ticketGroup.children.forEach(child =>{
        idList = idList.concat(this.getAllOffspring(child));
      });
    }
    return idList;
  }

  areaInOffspring(group, area){
    let areaIsInOffspring = false;
    let offspring = this.getAllOffspring(group);
    offspring.forEach(oId =>{
      if(oId != group.TicketGroupId && this.state.ticketAssignmentAreaGroups.findIndex(x => x.TicketGroupId == oId && (area.AssignmentAreaId ? x.AssignmentAreaId == area.AssignmentAreaId : (x.CompanyArcGisLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId))) != -1){
        areaIsInOffspring = true;
      }
    })
    return areaIsInOffspring;
  }

  areaInUsersAreas(area){
    if(area.AssignmentAreaId){
      return this.state.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1
    }
    else if(this.state.CompanyArcGisLayerFeatures){
      return this.state.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1
    }
    return false;
  }

  hasArea(ticketGroup){
    let hasArea = false;
    if(this.state.ticketAssignmentAreaGroups){
      let ticketGroups = this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == ticketGroup.TicketGroupId);
      ticketGroups.forEach(group =>{
        if(this.state.ticketAssignmentAreas && group.AssignmentAreaId){
          if(this.state.ticketAssignmentAreas.findIndex(x => x.AssignmentAreaId == group.AssignmentAreaId) != -1){
            hasArea = true;
          }
        }
        else if(this.state.CompanyArcGisLayerFeatures){
          if(this.state.CompanyArcGisLayerFeatures.findIndex(x => x.CompanyArcGISLayerId == group.CompanyArcGisLayerId && x.FeatureId == group.FeatureId) != -1){
            hasArea = true;
          }
        }
      });
    }
    return hasArea;
  }

  selectTicketArea(area, ticketGroup){
    let selectedAreas = this.state.selectedAreas;
    let selectedFeatures = this.state.selectedFeatures;
    let allFeatures = JSON.parse(JSON.stringify(this.state.CompanyArcGisLayerFeatures));
    let allAreas = JSON.parse(JSON.stringify(this.state.ticketAssignmentAreas));
    if(area == "ticketGroup"){
      //Need to either enable or disable all layers in this group
      //REMOVE
      var ticketAssignmentAreaGroups = this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == ticketGroup.TicketGroupId);
      if(this.isTicketGroupChecked(ticketGroup)){
        ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
          if(assignmentAreaGroup.AssignmentAreaId){
            var selectedAssignmentIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
            if(selectedAssignmentIndex != -1) selectedAreas.splice(selectedAssignmentIndex,1)
          }
          else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
            var selectedFeatureIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
            if(selectedFeatureIndex != -1) selectedFeatures.splice(selectedFeatureIndex,1);
          }
        });
      }
      //ADD ALL 
      else{
        ticketAssignmentAreaGroups.forEach(assignmentAreaGroup =>{
          if(assignmentAreaGroup.AssignmentAreaId){
            var selectedAssignmentIndex = selectedAreas.findIndex(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
            if(selectedAssignmentIndex == -1){
              var foundArea = allAreas.find(x => x.AssignmentAreaId == assignmentAreaGroup.AssignmentAreaId);
              if(foundArea) selectedAreas.push(foundArea);
            }
          }
          else if(assignmentAreaGroup.CompanyArcGisLayerId && assignmentAreaGroup.FeatureId){
            var selectedFeatureIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
            if(selectedFeatureIndex == -1){
              var foundFeature = allFeatures.find(x => x.CompanyArcGISLayerId == assignmentAreaGroup.CompanyArcGisLayerId && x.FeatureId == assignmentAreaGroup.FeatureId);
              if(foundFeature) selectedFeatures.push(foundFeature);
            }
          }
        });
      }
    }
    else if(area == "ANY"){
      //REMOVE
      if(selectedAreas.length == this.state.ticketAssignmentAreas.length && selectedFeatures.length == this.state.CompanyArcGisLayerFeatures.length){
        selectedAreas = [];
        selectedFeatures = [];
      }
      //ADD ALL
      else{
        selectedAreas = JSON.parse(JSON.stringify(this.state.ticketAssignmentAreas));
        selectedFeatures = allFeatures;
      }
    }
    else if(area.CompanyArcGISLayerId && area.FeatureId){
      let foundIndex = selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId);
      //remove
      if(foundIndex != -1){
        selectedFeatures.splice(foundIndex, 1);
      }
      //ADD
      else{
        selectedFeatures.push(area);
      }
    }
    else{
      let foundIndex = selectedAreas.indexOf(area);
      //REMOVE
      if(foundIndex != -1){
        selectedAreas.splice(foundIndex,1);
      }
      //ADD
      else{
        selectedAreas.push(area);
      }
    }
    this.setState({selectedAreas, selectedFeatures,refreshKeys : {ProductivityPastDays : this.state.refreshKeys.ProductivityPastDays+1},},()=>{
      this.setState({filterTicketAssignmentAreasValue : selectedAreas, filterTicketFeatures : selectedFeatures}, this.filterData(null,selectedAreas, selectedFeatures));
    });
  }

  expandTicketGroup(group){
    let ticketGroups = this.state.ticketGroups;
    let foundGroup  = ticketGroups.find(x => x.TicketGroupId == group.TicketGroupId);
    foundGroup.expanded = !group.expanded;
    this.setState({ticketGroups});
  }

  filterData(){
    let searchData = [];
    if((this.state.ticketAssignmentAreas && this.state.ticketAssignmentAreas.length > 0) || (this.state.CompanyArcGisLayerFeatures && this.state.CompanyArcGisLayerFeatures.length > 0)){
      if(this.state.selectedAreas.length == this.state.ticketAssignmentAreas.length && this.state.selectedFeatures.length == this.state.CompanyArcGisLayerFeatures.length){
      searchData = this.state.searchData;
      }
      else{
        this.state.searchData.forEach(data => {
          if(searchData.findIndex(x => x.UserId == data.UserId) == -1){
            let userAreas = this.state.userAssignmentAreas.filter(x => x.UserId == data.UserId);
            userAreas.forEach(area =>{
              if(area.AssignmentAreaId != null && this.state.selectedAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1){
                searchData.push(data);
              }
              else if(area.CompanyArcGisLayerId != null && area.FeatureId != null && this.state.selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1){
                searchData.push(data);
              }
            });
          }
        });
      }
    }
    else{
      searchData = this.state.searchData;
    }
    this.setState({filteredSearchData : searchData});
  }

  renderTicketGroup(groups, isChild){
    return (
      <ul className={isChild ? "" : ""} style={{paddingInlineStart : 15, marginLeft : isChild ? 0 : 15}}>
        {groups.map((group, index) =>
          (!group.DeletedDate && (group.isRoot || isChild) && this.hasArea(group)) &&
          <li  key={index} style={{marginTop : 0}}>
            <div  className={ group.children ? "itemLine" : group.isRoot ? "row itemLine" : "itemLine"} style={{display : "flex", flexDirection : 'row'}}>
              <input checked={this.isTicketGroupChecked(group)} type="checkbox" onChange={()=>{this.selectTicketArea("ticketGroup",group);}}/>
              <label style={{marginLeft : 5, cursor : "pointer", position : "relative", top : 3, fontWeight:"bold"}} onClick={() =>{this.selectTicketArea("ticketGroup",group); }} className="">{group.GroupLabel}</label>
              {(group.children || (this.state.ticketAssignmentAreaGroups && this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).length > 0))? (group.expanded ? <KeyboardArrowDownIcon className="arrowIcon  dark caret" onClick={()=>{this.expandTicketGroup(group)}}/> : <KeyboardArrowRightIcon className="arrowIcon dark caret" onClick={()=>{this.expandTicketGroup(group)}}/>) : <div style={{minWidth : 25}}/>}
            </div>
            {((this.state.ticketAssignmentAreaGroups && this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).length > 0) && group.expanded)&& 
              <ul style={{paddingInlineStart : 15}}> {this.state.ticketAssignmentAreaGroups.filter(x => x.TicketGroupId == group.TicketGroupId).map((area, ticketIndex) => (
                (!this.areaInOffspring(group, area) && this.areaInUsersAreas(area)) && <li className="itemLine" key={ticketIndex}>
                  <input checked={area.AssignmentAreaId ? this.state.selectedAreas.findIndex(x => x.AssignmentAreaId == area.AssignmentAreaId) != -1 : this.state.selectedFeatures.findIndex(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId) != -1 } onChange={() =>{this.selectTicketArea(area.AssignmentAreaId ? this.state.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId) : this.state.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId));}} type="checkbox"/>
                  <label style={{marginLeft : 5, cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area.AssignmentAreaId ? this.state.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId) : this.state.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId));}} className="">
                    {area.AssignmentAreaId ? 
                    (this.state.ticketAssignmentAreas && this.state.ticketAssignmentAreas.find(x => x.AssignmentAreaId == area.AssignmentAreaId)) ? this.state.ticketAssignmentAreas.find( x => x.AssignmentAreaId == area.AssignmentAreaId).Label : ""
                    :
                    (this.state.CompanyArcGisLayerFeatures && this.state.CompanyArcGisLayerFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId)) ? this.state.CompanyArcGisLayerFeatures.find( x => x.CompanyArcGISLayerId == area.CompanyArcGisLayerId && x.FeatureId == area.FeatureId).FeatureName : ""}</label>
                </li>
              ))}</ul>
            }
            {group.children && group.expanded && this.renderTicketGroup(group.children, true)}
          </li>
        )}
      </ul>
    )
  }

  render(){
    return(<MuiPickersUtilsProvider utils={DateFnsUtils}><div>
      <div className="w-100 d-flex flex-row justify-content-center align-items-center counters-container">
          {((this.state.ticketAssignmentAreas && this.state.ticketAssignmentAreas.length > 0) || (this.state.CompanyArcGisLayerFeatures && this.state.CompanyArcGisLayerFeatures.length > 0)) && 
            <div id={"assignmentAreasList"} className="dropdown-check-list-searchstrip" style={{marginTop: 5}}>
              <span className="anchor itemsFont" style={{maxWidth:150, minHeight: 41}} onClick={() =>{
                let checkList = document.getElementById("assignmentAreasList");
                if(checkList.classList.contains("visible")) checkList.classList.remove("visible");
                else checkList.classList.add("visible");
                }}><span style={{top: 11, position: 'absolute', color:'#7b7b7b'}}>Ticket Areas</span>
              </span>
              <ul className="items itemsFont" style={{width:277, maxHeight:500}}>
                <li style={{fontWeight: 700, color: '#16548e'}} className="itemLine">
                <input checked={this.state.ticketAssignmentAreas.length == this.state.selectedAreas.length && this.state.CompanyArcGisLayerFeatures.length == this.state.selectedFeatures.length} onChange={() =>{this.selectTicketArea("ANY");}} type="checkbox"/>
                <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea("ANY");}}> All Areas</div>
                </li>
                {(this.state.ticketGroups) && <li>{this.renderTicketGroup(this.state.ticketGroups, false)}</li>}
                {this.state.ticketAssignmentAreas.map((area, i) => (!area.HideFromDropDown && (!area.HideFromDropDown && (!this.state.ticketGroups || (!this.state.ticketAssignmentAreaGroups.find(x => x.AssignmentAreaId == area.AssignmentAreaId))))) && 
                  <li key={i} style={{}} className="itemLine">
                  <input checked={this.state.selectedAreas.includes(area)} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                  <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area}</div>
                </li>)}
                {this.state.CompanyArcGisLayerFeatures.map((area, i) => ( ((!this.state.ticketGroups) || (!this.state.ticketAssignmentAreaGroups.find(x => x.CompanyArcGisLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId))) &&
                  <li key={i} style={{}} className="itemLine">
                    <input checked={this.state.selectedFeatures.find(x => x.CompanyArcGISLayerId == area.CompanyArcGISLayerId && x.FeatureId == area.FeatureId) != null} onChange={() =>{this.selectTicketArea(area);}} type="checkbox"/>
                    <div style={{maringLeft : 5, display: "inline", cursor : "pointer"}} onClick={() =>{this.selectTicketArea(area);}}> {area.FeatureName} </div>
                  </li>
                ))

                }
              </ul>
            </div>

           }

          {/* DATETIME EDIT */}
          {!this.state.editMode ? 
            
            <div className="d-flex flex-row ">

              {/* FROM PICKER */}
              <Button className="buttonAction btn btn-primary dehighlighted" onClick={this.toggleStartDatePicker}  >
                <DateRange className="buttonIcon light" style={{marginRight:4, marginBottom:2}}/>
                <label className="buttonText withIcon small" id="FromDate" style={{ margin: 0, cursor: "pointer" }}>
                  {this.state.startDate}
                </label>
              </Button>
              {this.state.startPickerOpen && ( <DatePicker value={this.state.startDate} onChange={this.startHandleChange} onClose={ () => {this.setState({startPickerOpen: false})}} open={this.state.startPickerOpen} />)}
              

            </div> : null

           
          }

        </div>
        <div id="forms-tab-container" style={{ overflowY: "hidden"}}>
          {this.state.loading && <div className="row mapspin">
              <div className="spinnerDiv">
                <div className="authSpinnerDiv">
                  <div className="authSpinnerDivMessage">{this.state.loading ? "Loading...": "Loading Layout..."}</div>
                  <div className="barLoaderPortal">
                    <BarLoader sizeUnit={"px"} size={150} color={"#095399"} loading={true}/>
                  </div>
                </div>
              </div>
            </div>}
        
        <div className='dataTable_staticHeader'>
          <div data-test="datatable-input" className="dataTables_filter2">
            <div className="searchLabel">
            <SearchSharpIcon className="buttonIcon light largeLightMargin"/><input type="search"
            onChange={(e)=>{let filteredSearchData = this.state.searchData.filter(x => x.DisplayName.toLowerCase().includes(e.target.value.toLowerCase()));
            this.setState({searchFilter : e.target.value,filteredSearchData})}} className="form-control form-control-sm noBorder" placeholder="Search" value={this.state.searchFilter}/></div>
          </div>
          <div className="dataTable_headerRow">
            <div onClick={()=>{
              this.handleSort("DisplayName");
              this.setState({displayNameDesc : !this.state.displayNameDesc,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.displayNameDesc != null && this.state.displayNameDesc != undefined ? this.state.displayNameDesc ? "underline" : "overline" : "",width: "10.5%"}}>NAME</div>
            <div onClick={()=>{
              this.handleSort("TicketsPerHour");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : !this.state.ticketsPerHourDesc,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.ticketsPerHourDesc != null && this.state.ticketsPerHourDesc != undefined ? this.state.ticketsPerHourDesc ? "underline" : "overline" : "",width: "5.5%", }}>PER HOUR</div>
            <div onClick={()=>{
              this.handleSort("FirstSync");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : !this.state.firstSyncDesc,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.firstSyncDesc != null && this.state.firstSyncDesc != undefined ? this.state.firstSyncDesc ? "underline" : "overline" : "",width: "15%", }}>First Sync</div>
            <div onClick={()=>{
              this.handleSort("LastSync");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : !this.state.lastSyncDesc,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.lastSyncDesc != null && this.state.lastSyncDesc != undefined ? this.state.lastSyncDesc ? "underline" : "overline" : "",width: "15%", }}>Last Sync</div>
            <div onClick={()=>{
              this.handleSort("TimeElapsed");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : !this.state.timeElapsedDesc,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.timeElapsedDesc != null && this.state.timeElapsedDesc != undefined ? this.state.timeElapsedDesc ? "underline" : "overline" : "",width: "4.5%", }}>Time</div>
            <div onClick={()=>{
              this.handleSort("TicketsOpen");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : !this.state.ticketsOpenDesc,ticketsClosedDesc : null,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.ticketsOpenDesc != null && this.state.ticketsOpenDesc != undefined ? this.state.ticketsOpenDesc ? "underline" : "overline" : "",width: "4%", }}>OPEN</div>
            <div onClick={()=>{
              this.handleSort("TicketsClosed");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : !this.state.ticketsClosedDesc,ticketsProjectedDesc : null});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.ticketsClosedDesc != null && this.state.ticketsClosedDesc != undefined ? this.state.ticketsClosedDesc ? "underline" : "overline" : "",width: "4%", }}>CLOSED</div>
            <div onClick={()=>{
              this.handleSort("TicketsProjected");
              this.setState({displayNameDesc : null,ticketsPerHourDesc : null,firstSyncDesc : null,lastSyncDesc : null,timeElapsedDesc : null,ticketsOpenDesc : null,ticketsClosedDesc : null,ticketsProjectedDesc : !this.state.ticketsProjectedDesc});
            }} className="dataTable_headerBold" style={{textDecoration : this.state.ticketsProjectedDesc != null && this.state.ticketsProjectedDesc != undefined ? this.state.ticketsProjectedDesc ? "underline" : "overline" : "",width: "5.5%", }}>PROJECTED</div>
            <div style={{width: "7%", }} className="dataTable_headerBold">DATE</div>
          </div>
        </div>

        {(this.state.searchData && this.state.searchData.length > 0 && !this.state.loading) && 
                <GridLayout
                  style={{height:window.innerHeight,width:'100%'}}
                  cols={12}
                  id="dashboard-forms-grid"
                  className="d-flex justify-content-between hide-vertical-scrollbar gridLayoutColored"
                  layout={this.state.layout}
                  onLayoutChange={this.saveLayout}
                  rowHeight={50}
                  margin={[10, 10]}
                  autoSize={true}
                  isDraggable={false}
                  onResizeStop={this.handleResizeChange}
                  // onDragStop={()=>{this.setState({draggingElement : true})}}
                >
                  
                <div key="dataTable">

                    {this.state.filteredSearchData.map((element, index) => (
                      <div key={index} className="dataTable_rowHighlight" style={{backgroundColor : index %2 == 0 ? "rgba(0, 0, 0, 0.05)" : "",width: '100%', display : 'flex',flexDirection : "row",marginBottom : 5, marginTop : 5}}>
                        <div className='dataTable_rowStandard' style={{width: "12.5%",marginLeft:10, fontWeight:'bold', color:'##003b62', fontSize:15, textTransform: 'uppercase', textShadow:'0px 0px 2px #ffffff;'}}>{element.DisplayName}</div>
                        <div className='dataTable_rowStandard' style={{width: "4.5%", }}>{element.TicketsPerHour}</div>
                        <div className='dataTable_rowStandard' style={{width: "15%", }}>{element.FirstSync}</div>
                        <div className='dataTable_rowStandard' style={{width: "15%", }}>{element.LastSync}</div>
                        <div className='dataTable_rowStandard' style={{width: "4.5%", }}>{element.TimeElapsed}</div>
                        <div className='dataTable_rowStandard' style={{width: "4.5%", }}>{element.TicketsOpen}</div>
                        <div className='dataTable_rowStandard' style={{width: "4%", }}>{element.TicketsClosed}</div>
                        <div className='dataTable_rowStandard' style={{width: "4%", }}>{element.TicketsProjected}</div>
                        <ProductivityPastDays filterCallback={this.filterTable} 
                        refreshKey={this.state.refreshKeys.ProductivityPastDays} 
                        startDate={this.state.startDate} 
                        data={[element.DayCount1,element.DayCount2,element.DayCount3,element.DayCount4,element.DayCount5]} 
                        />
                    </div>))}
                    {/* <MDBDataTable striped bordered hover paging={false} data={tableData} /> */}
                  </div>
                </GridLayout>}
        </div>
    </div></MuiPickersUtilsProvider>)
  }
}

TicketProductivityTab.propTypes = {
  getLocallySavedDashboard: PropTypes.func,
  saveDataLocally : PropTypes.func
}
export default TicketProductivityTab;